import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";

import LaddaButton, { XL, SLIDE_UP } from "react-ladda";


import { addFieldError, clearFieldError, validateEmail } from "../helpers/util";

import { CONSTANTS } from "../helpers/constants.js";

const TEAMS_URL = CONSTANTS.TEAMS_URL;

class Contacts extends Component {
  state = {
    loading: false
  };
  constructor(props) {
    super(props);
    this.name = React.createRef();
    this.email = React.createRef();
    this.subject = React.createRef();
    this.message = React.createRef();
  }
  getFormData() {
    const formData = {};
    formData.name = this.name.current.value;
    formData.email = this.email.current.value;
    formData.subject = this.subject.current.value;
    formData.message = this.message.current.value;
    console.log(formData);
    return formData;
  }

  validateData(formData) {
    let valid = true;

    if (formData.name == "") {
      toast.error("Please enter your name");
      valid = false;
    }

    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email");
      valid = false;
    }

    if (formData.subject == "") {
      toast.error("Please enter a subject");
      valid = false;
    }

    if (formData.message == "") {
      toast.error("Please enter a message");
      valid = false;
    }
    return valid;
  }
  contact() {
    let formData = this.getFormData();

    let valid = this.validateData(formData);

    formData.api_key = "123456";

    if (valid) {
      this.setState({
        loading: true
      });
      fetch(`${TEAMS_URL}/api/v1/marketing`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(formData)
      })
        .then(result => {
          // l.stop();
          this.setState({
            loading: false
          });
          return result.json();
        })
        .then(submission => {
          if (submission.status === "error") {
            toast.error(submission.message);
          } else
            toast.success(
              "Thank you for submitting your application, we'll contact you shortly."
            );
        });
    }
  }
  render() {
    return (
      <section
        className="contact_info_area  bg_color"
        style={{
          paddingTop: "80px",
          paddingBottom: "80px",
          marginRight: "10%",
          marginLeft: "10%",
          borderRadius: "45px"
        }}
      >
        <ToastContainer position={"bottom-right"} />

        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                  Office Address
                </h6>
                <div style={{ color: "#6dacde" }}>
                  <div
                    className={"address_bubble"}
                    style={{ backgroundColor: "#6dacde" }}
                  />
                  CANADA
                </div>
                <p className="f_400 f_size_15">
                  1502, 200 Bay Street, K1R 7W8, Ottawa, ON.
                </p>
                <div style={{ color: "#f47846" }}>
                  <div style={{ color: "#f47846" }}>
                    <div
                      className={"address_bubble"}
                      style={{ backgroundColor: "#f47846" }}
                    />
                    UAE
                  </div>
                </div>
                <p className="f_400 f_size_15">
                  Level 4, GQC Silver Tower, Abu Dhabi
                </p>
              </div>
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                  Contact Info
                </h6>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">Phone:</span>{" "}
                  <a href="tel:+971502507855-">(+971) 50 250 7855</a>
                </p>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">Email:</span>{" "}
                  <a href="mailto:admin@meronex.com">admin@meronex.com</a>
                </p>
              </div>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
                Leave a Message
              </h2>
              <form action="#" className="contact_form_box" id="contactForm">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        ref={this.name}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        ref={this.email}
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        ref={this.subject}
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea
                        ref={this.message}
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Enter Your Message . . ."
                      />
                    </div>
                  </div>
                </div>
                <LaddaButton
                  className={"btn_three"}
                  loading={this.state.loading}
                  onClick={e => {
                    e.preventDefault();
                    this.contact();
                  }}
                  data-color="#eee"
                  data-size={XL}
                  data-style={SLIDE_UP}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                >
                  Send Message
                </LaddaButton>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
