import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";
class Featuresitems extends Component {
  render() {
    var { rowClass, fimage, iImg, ftitle, descriptions, aClass } = this.props;

    const image = {
      url: typeof fimage === "object" ? fimage.url : fimage,
      height: fimage.height || "300px",
      width: fimage.width || "450px",
      backgroundSize: fimage.backgroundSize || "contain",
      backgroundPosition: fimage.backgroundPosition || "50% 50%"
    };
    return (
      <div className={`agency_featured_item ${rowClass} `}>
        <div className="col-lg-6">
          <Reveal effect="fadeInLeft">
            <div
              className="agency_featured_img text-right"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                alt=""
                style={{
                  backgroundImage: `url(${image.url}`,
                  height: image.height,
                  width: image.width,
                  backgroundSize: image.backgroundSize,
                  backgroundPosition: image.backgroundPosition,
                  backgroundColor: "white",
                  borderRadius: "5%",
                  boxShadow: "0px 4px 6px 0px rgba(12, 0, 46, 0.06)",
                  backgroundRepeat: "no-repeat"
                }}
              />
            </div>
          </Reveal>
        </div>
        <div className="col-lg-6">
          <div
            className={`agency_featured_content wow fadeInLeft ${aClass}`}
            data-wow-delay="0.6s"
          >
            <Reveal effect="fadeInRight">
              <div className="dot">
                <span className="dot1" />
                <span className="dot2" />
              </div>
              <h3>{ftitle}</h3>
              <p>{descriptions}</p>
            </Reveal>
          </div>
        </div>
      </div>
    );
  }
}

export default Featuresitems;
