/**
 * Created by Alawi on 2017-01-03.
 */

export function validateEmail(email) {
  console.log("validate email called");
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function addFieldError(field, msg) {
  console.log("calling add field error");
  if (typeof field != "undefined") {
    if (!field.parent().hasClass("has-error")) {
      field.parent().addClass("has-error");
      field.parent().append("<span class='help-block'>" + msg + "</span>");
    }
  } else {
    console.warn("field is undefined");
  }
}

export function clearFieldError(field) {
  console.log("clear field error called");

  if (typeof field != "undefined") {
    field.parent().removeClass("has-error");
    field
      .parent()
      .find(".help-block")
      .remove();
  } else console.warn("field is undefined");
}
