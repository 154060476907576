import React from "react";
import Reveal from "react-reveal/Reveal/";
import Hidden from "@material-ui/core/Hidden";
import { Link, NavLink } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IoMdArrowRoundBack } from "react-icons/io";

import { FaArrowDown } from "react-icons/fa";
import ScrollIntoView from "react-scroll-into-view";

export default function SolutionBanner(props) {
  const mobile = useMediaQuery("(max-width:600px)");
  const { info } = props;
  return (
    <section className="seo_home_area">
      <Hidden smDown>
        <div className="home_bubble">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
        </div>
      </Hidden>
      <div className="banner_top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center seo_banner_content">
              <Reveal effect="fadeInUp" duration={500}>
                <div>
                  <Link to={"/"}>
                    <div
                      style={{
                        position: "absolute",
                        color: "#35353514",
                        fontSize: "16px",
                        top: "-18px"
                      }}
                    >
                      <IoMdArrowRoundBack size={50} />
                    </div>
                  </Link>

                  <img
                    src={info.img}
                    alt=""
                    style={{ width: mobile ? "100%" : "40%" }}
                  />
                </div>
                <h2>
                  <span>{info.title}</span>
                </h2>
                <span className={"seo_service_subtitle"}>{info.subtitle}</span>
                <div />
              </Reveal>
              <Reveal effect="fadeInUp" duration={1000}>
                <p className="wow fadeInUp" data-wow-delay="0.5s">
                  {info.description}
                </p>
              </Reveal>
            </div>
          </div>
        </div>
        <div
          className="arrow bounce"
          style={{ cursor: "pointer", marginTop: "50px" }}
        >
          <ScrollIntoView selector="#solution-content">
            <FaArrowDown size={30} />
          </ScrollIntoView>
        </div>
      </div>
    </section>
  );
}
