import React from "react";
import Featuresitems from "../../components/Features/Featuresitems";
import SolutionLayout from "./SolutionLayout";

const Process = () => {
  return (
    <SolutionLayout
      bannerInfo={{
        title: "Clinics",
        img: require("../../img/solutions/clinics.jpg"),
        description:
          "Empower your patients with a modern app that would allow them to access their recommendations, book new sessions, get reminders and make referrals to their friends and family.",
        subtitle: "For Clinics Managers"
      }}
    >
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={{
          url: require("../../img/solutions/clinics/patients.jpg"),
          backgroundSize: "cover"
        }}
        iImg="icon01.png"
        ftitle="Patient Application"
        descriptions={
          <div>
            <ul>
              <li>Manage your sessions reservation</li>
              <li>View your diagnosis</li>
              <li>View treatment plans and recommendations</li>
              <li>Make referrals and earn points</li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row"
        aClass="pl_100"
        fimage={{
          url: require("../../img/solutions/clinics/marketing.jpg"),
          backgroundSize: "cover"
        }}
        ftitle="Marketing & Referrals"
        descriptions={
          <div>
            <ul>
              <li>Manage your referral clinics and doctors relationships</li>
              <li>Manage your online bookings</li>
              <li>Create customer referrals programs</li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={{
          url: require("../../img/solutions/clinics/portal.jpg"),
          backgroundSize: "cover"
        }}
        ftitle="Management Portal"
        descriptions={
          <div>
            <ul>
              <li>View all your clinics info from one place</li>
              <li>View patients events and progress</li>
              <li>Create recommendations and exercises template</li>
              <li>Manage your referral doctors and clinics relationship</li>
              <li>Generate reports and KPIs</li>
            </ul>
          </div>
        }
      />
    </SolutionLayout>
  );
};
export default Process;
