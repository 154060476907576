import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

/*------ Pages-----*/
import { Home } from "./Pages/Home";
import Buildings from "./Pages/solutions/Buildings";
import Offices from "./Pages/solutions/Offices";
import Clinics from "./Pages/solutions/Clinics";

import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";

class App extends Component {
  //   componentDidMount() {
  //     this.props.hideLoader();
  // }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path="/buildings" component={Buildings} />
          <ScrollToTopRoute path="/offices" component={Offices} />
          <ScrollToTopRoute path="/clinics" component={Clinics} />
          <ScrollToTopRoute path="/contact" component={Contact} />
          <Route component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default App;
