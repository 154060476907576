import React from "react";
import Reveal from "react-reveal/Reveal/";
import Hidden from "@material-ui/core/Hidden";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FaArrowDown } from "react-icons/fa";
import ScrollIntoView from "react-scroll-into-view";

export default function SolutionBanner(props) {
  const mobile = useMediaQuery("(max-width:600px)");
  const { info } = props;
  return (
    <section className="seo_home_area">
      <div className="home_bubble">
        <div className="bubble b_one" />
        <Hidden smDown>
          <div className="bubble b_two" />
        </Hidden>
        <div className="bubble b_three" />
        <div className="bubble b_four" />
        <Hidden smDown>
          <div className="bubble b_five" />
        </Hidden>
        <div className="bubble b_six" />
      </div>
      <div className="banner_top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center seo_banner_content">
              <Reveal effect="fadeInUp" duration={500}>
                <div>
                  <img
                    src={info.img}
                    alt=""
                    style={{ width: mobile ? "100%" : "40%" }}
                  />
                </div>
                <h2>
                  <span>Contact Us</span>
                </h2>
                <div />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
