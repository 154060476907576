import React from "react";
import Featuresitems from "../../components/Features/Featuresitems";
import SolutionLayout from "./SolutionLayout";

const Process = () => {
  return (
    <SolutionLayout
      bannerInfo={{
        title: "Offices",
        img: require("../../img/solutions/offices.jpg"),
        description:
          "Enhance your office culture, training and communication by offering your employees modern application and portal.",
        subtitle: "For HR & Office Managers"
      }}
    >
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={{
          url: require("../../img/solutions/offices/culture.jpg"),
          backgroundSize: "cover"
        }}
        ftitle="Culture Management"
        descriptions={
          <div>
            <ul>
              <li>Engage your people using real-time news-feed</li>
              <li>Communicate your company's vision, mission and values</li>
              <li>Share blogs, training materials and other resources</li>
              <li>Maintain a directory of your organization members</li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row"
        aClass="pl_100"
        fimage={{
          url: require("../../img/solutions/offices/team.jpg"),
          backgroundSize: "cover"
        }}
        iImg="icon02.png"
        ftitle="Team Management"
        descriptions={
          <div>
            <ul>
              <li>Maintain your team long-term memory using notes and tags</li>
              <li>Generate tasks from your notes</li>
              <li>Let AI suggest your next task</li>
              <li>Manage both in-office and remote teams</li>
              <li>Automatically generate documents from your notes</li>
              <li>Use chat bot integrations for communication</li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={{
          url: require("../../img/solutions/offices/process.jpg"),
          backgroundSize: "cover"
        }}
        ftitle="Process Management"
        descriptions={
          <div>
            <ul>
              <li>
                Implement your custom process and integrate it with the work
                module using Meronex Platform
              </li>
              <li>Create custom reports and KPIs</li>
              <li>
                Reuse many of our high quality widgets (spreadsheets, charts
                etc.)
              </li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row"
        aClass="pl_100"
        fimage={{
          url: require("../../img/solutions/portal.jpg"),
          backgroundSize: "cover"
        }}
        iImg="icon02.png"
        ftitle="Management Portal"
        descriptions={
          <div>
            <ul>
              <li>View all your branches performance from one place</li>
              <li>Explore each branch organizational hierarchy</li>
              <li>Create notes and automatically notify target members</li>
              <li>Create custom reports and dashboard</li>
              <li>Explore your organization internal and external news</li>
              <li>
                Manage your organization and teams in-office and remotely from
                an app
              </li>
            </ul>
          </div>
        }
      />
    </SolutionLayout>
  );
};
export default Process;
