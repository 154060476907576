import React from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";

import { FaHandshake, FaCode } from "react-icons/fa";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const Service = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  const imgHeight = mobile ? "235px" : "255px";
  return (
    <React.Fragment>
      <section className="seo_service_area">
        <div className="container">
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <Hidden smUp>
                <div
                  style={{
                    marginBottom: "30px",
                    marginTop: "-20px",
                    color: "#b9b9b9",
                    width: "100vw",
                    textAlign: "center"
                  }}
                >
                  OUR SOLUTIONS
                </div>
              </Hidden>
              <div className="col-lg-4 col-md-6">
                <Link to={"/buildings"}>
                  <div className="seo_service_item">
                    <img
                      src={require("../../img/solutions/buildings.jpg")}
                      alt=""
                      style={{ width: "100%", height: imgHeight }}
                    />
                    <div>
                      <a href=".#">
                        <h4>Buildings</h4>
                      </a>
                      <span className={"seo_service_subtitle"}>
                        For Buildings Managers{" "}
                      </span>

                      <p>
                        Increases your tenants acquisition and retention by
                        offering best-in-class marketing and residential
                        experiences.
                      </p>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px"
                        }}
                      >
                        <a href=".#">
                          <i className="arrow_right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <Link to={"/offices"}>
                  <div className="seo_service_item">
                    <img
                      src={require("../../img/solutions/offices.jpg")}
                      alt=""
                      style={{ width: "100%", height: imgHeight }}
                    />{" "}
                    <a href=".#">
                      <h4>Offices</h4>
                    </a>
                    <span className={"seo_service_subtitle"}>
                      For HR & Office Managers
                    </span>
                    <p>
                      Enhance your office culture, training and communication by
                      offering your employees modern application and portal.
                    </p>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px"
                      }}
                    >
                      <a href=".#">
                        <i className="arrow_right" />
                      </a>
                    </div>
                  </div>
                </Link>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <Link to={"/clinics"}>
                  <div className="seo_service_item">
                    <img
                      src={require("../../img/solutions/clinics.jpg")}
                      alt=""
                      style={{ width: "100%", height: imgHeight }}
                    />
                    <a href=".#">
                      <h4>Clinics</h4>
                    </a>
                    <span className={"seo_service_subtitle"}>
                      For Clinics Managers
                    </span>
                    <p>
                      Empower your patients with a modern app that would allow
                      them to access their recommendations, book new sessions,
                      get reminders and make referrals to their friends and
                      family.
                    </p>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px"
                      }}
                    >
                      <a href=".#">
                        <i className="arrow_right" />
                      </a>
                    </div>
                  </div>
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <img
                  src={require("../../img/solutions/services.jpg")}
                  alt=""
                  style={{
                    width: "150%"
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>Do you have something else in mind?</h2>
                  <p>
                    Whether it is a new website, custom app, digital marketing,
                    systems integration, business intelligence & analytics or
                    business process streamlining, we have the necessary
                    expertise to help you.
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <FaHandshake size={25} />
                    </div>
                    <div className="media-body">
                      <h3>Digital Consultancy Services</h3>
                      <p>
                        We will listen to you carefully, analyze your options
                        and provide you with best recommendations to your most
                        critical technical business needs.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <FaCode size={25} />
                    </div>
                    <div className="media-body">
                      <h3>Custom Product Development</h3>
                      <p>
                        Do you have an idea you want to bring to market? Are you
                        looking for tailored solution to your business? We will
                        use our extensive development experience to make your
                        ideas a reality.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
