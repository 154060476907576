import React from "react";
import Reveal from "react-reveal/Reveal/";
import Hidden from "@material-ui/core/Hidden";

const DesignBanner = () => {
  return (
    <section className="seo_home_area">
      <Hidden smDown>
        <div className="home_bubble">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
        </div>
      </Hidden>
      <div className="banner_top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center seo_banner_content">
              <Reveal effect="fadeInUp" duration={500}>
                <h2>Modern Business Solutions</h2>
              </Reveal>
              <Reveal effect="fadeInUp" duration={1000}>
                <p className="wow fadeInUp" data-wow-delay="0.5s">
                  We create modern business experiences using web, mobile and AI
                  technologies.
                </p>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DesignBanner;
