const devMode = false;

export const CONSTANTS = {
  TEAMS_URL: devMode ? "http://localhost:3000" : "https://team.meronex.com",
  LOGO_WHITE: "assets/img/logo/test/asset2.png",
  LOGO_DARK: "assets/img/logo/test/asset2.png",
  TEAMS_ASSETS_URL: "https://team.meronex.com/assets/meronex-teams",
  ROOT_URL: devMode
    ? "http://localhost:3000"
    : "https://communities.meronex.com"
};
