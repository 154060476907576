import React, { Component } from "react";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
class Footer extends Component {
  render() {
    return (
      <footer className="new_footer_area bg_color">
        <div
          style={{
            top: "13px",
            width: "100%",
            position: "relative"
          }}
        >
          <svg
            viewBox="0 0 1440 120"
            style={{
              fill: "#fff",
              position: "absolute",
              bottom: "0px",
              width: "100%"
            }}
          >
            <path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z" />
          </svg>
          <div
            style={{
              textAlign: "center",
              padding: "20px"
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="footer-btm"
                  style={{
                    position: "relative",
                    top: "-15px"
                  }}
                >
                  <span>
                    <small className="copyright landing">
                      <img
                        className="meronex-logo"
                        src={require("../../img/logo.png")}
                        style={{
                          position: "relative",
                          top: "-3px",
                          left: "-6px"
                        }}
                        width="40"
                        height="40"
                      />{" "}
                      Copyright © {new Date().getFullYear()} Meronex. All rights
                      reserved.
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
