import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import DesignBanner from "../components/Banner/DesignBanner";
import Service from "../components/Service/Service";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "react-scroll-up";
import { MdArrowUpward } from "react-icons/md";

export const Home = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <DesignBanner />
    <Service />
    <Subscribe />
    <Footer />
    <ScrollToTop showUnder={160}>
      <MdArrowUpward size={20} />
    </ScrollToTop>
  </div>
);
