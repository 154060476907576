import React from "react";
import Featuresitems from "../../components/Features/Featuresitems";
import SolutionLayout from "./SolutionLayout";

const Process = () => {
  return (
    <SolutionLayout
      bannerInfo={{
        title: "Buildings",
        img: require("../../img/solutions/buildings.jpg"),
        description:
          "Increases your tenants acquisition and retention by offering best-in-class marketing and residential experiences.",
        subtitle: "For Buildings Managers"
      }}
    >
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={require("../../img/solutions/buildings/community.jpg")}
        iImg="icon01.png"
        ftitle="Community Management"
        descriptions={
          <div>
            Increase your members satisfaction and retention by offering modern
            community experiences:
            <ul>
              <li>Share announcements</li>
              <li>Offer services</li>
              <li>Gather feedback & conduct surveys</li>
              <li>
                Encourage sharing & collaboration using community tokens and
                rewards
              </li>
              <li>Connect your community to the city </li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row"
        aClass="pl_100"
        fimage={require("../../img/solutions/buildings/marketing.jpg")}
        ftitle="Marketing Management"
        descriptions={
          <div>
            Increase your tenants acquisitions by using modern marketing
            technologies and ecosystem:
            <ul>
              <li>Announce vacancies to real-estate agents</li>
              <li>
                Coordinate the viewing process between agents, prospects and
                on-site staff
              </li>
              <li>Gather feedback from agents after viewings</li>
              <li>Generate vacancy reports </li>
              <li>Create unique marketing presentation for each unit</li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row flex-row-reverse"
        aClass="pr_70 pl_70"
        fimage={require("../../img/solutions/buildings/site.jpg")}
        iImg="icon3.png"
        ftitle="Sites Management"
        descriptions={
          <div>
            Ensure your tenants satisfaction by providing the best site
            experiences:
            <ul>
              <li>Capture on-site observations using mobile devices</li>
              <li>Assign tasks to on-site staff</li>
              <li>Be pro-active and perform regular routine audits</li>
              <li>
                Empower your on-site staff to capture observations and gather
                feedback
              </li>
              <li>
                Track tasks progress, perform follow-ups and generate reports
              </li>
            </ul>
          </div>
        }
      />
      <Featuresitems
        rowClass="row"
        aClass="pl_100"
        fimage={require("../../img/solutions/portal.jpg")}
        iImg="icon02.png"
        ftitle="Management Portal"
        descriptions={
          <div>
            Get full visibility over your buildings using modern user-friendly
            portal:
            <ul>
              <li>View all your buildings info from one place</li>
              <li>Manage your tenants, units and their assets</li>
              <li>Manage documents and surveys</li>
              <li>One-click unit marketing</li>
              <li>Create custom audits, reports & KPIs</li>
              <li>Customize it to fit your needs </li>
              <li>Cross-platform friendly (work on all devices)</li>
            </ul>
          </div>
        }
      />
    </SolutionLayout>
  );
};
export default Process;
