import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import { Link } from "react-router-dom";

class Subscribe extends Component {
  render() {
    // let FooterData = this.props.FooterData;
    return (
      <React.Fragment>
        <section className="seo_fact_area sec_pad">
          <div className="home_bubble">
            <div className="bubble b_one" />
            <div className="bubble b_three" />
            <div className="bubble b_four" />
            <div className="bubble b_six" />
            <div
              className="triangle b_eight"
              data-parallax="{&quot;x&quot;: 120, &quot;y&quot;: -10}"
            >
              <img src="img/seo/triangle_one.png" alt="" />
            </div>
          </div>
          <div className="container">
            <div className="seo_call_action_text">
              <div>
                <h2>Ready to get started?</h2>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p className="">
                      Get a free consultation session or request a demo for one
                      of our products.
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ textAlign: "center" }}>
                      <Link to={"/contact"}>
                        <a href="#" className="about_btn">
                          Book Your Session Now
                        </a>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Subscribe;
