import React from "react";
import ScrollToTop from "react-scroll-up";
import { MdArrowUpward } from "react-icons/md";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer/Footer";

import ContactUsBanner from "../components/Banner/ContactUsBanner";
const About = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <ContactUsBanner
        info={{
          title: "BUILDINGS",
          img: "",
          description:
            "Increases your tenants acquisition and retention by offering best-in-class marketing and residential experiences.",
          subtitle: "For Buildings Managers"
        }}
      />
      <Contacts />
      <Footer />
      <ScrollToTop showUnder={160}>
        <MdArrowUpward size={20} />
      </ScrollToTop>
    </div>
  );
};
export default About;
