import React from "react";
import CustomNavbar from "../../components/CustomNavbar";
import SolutionBanner from "../../components/Banner/SolutionBanner";
import Featuresitems from "../../components/Features/Featuresitems";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { MdArrowUpward } from "react-icons/md";

export default function SolutionLayout(props) {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <SolutionBanner info={props.bannerInfo} />
      <div
        id="solution-content"
        style={{
          marginTop: "-60px",
          width: "100%",
          position: "relative",
          transform: "scaleY(-1)"
        }}
      >
        <svg
          viewBox="0 0 1440 120"
          style={{
            fill: "#fff",
            position: "absolute",
            bottom: "0px",
            width: "100%"
          }}
        >
          <path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z" />
        </svg>
      </div>
      <section className="process_area bg_color sec_pad">
        <div className="container">
          <div className="features_info">{props.children}</div>
        </div>
        <div
          className="seo_call_action_text"
          style={{
            textAlign: "center",
            position: "relative"
          }}
        >
          <div
            className="dot middle_dot"
            style={{
              position: "relative",
              top: "42px"
            }}
          >
            <span className="dot1" />
            <span className="dot2" />
          </div>
          <Link to={"/contact"}>
            <a
              href="#"
              className="about_btn"
              style={{
                width: "350px",
                backgroundColor: "rgb(154, 156, 159)",
                color: "white",
                borderRadius: "50px"
              }}
            >
              Book a Demo
            </a>
          </Link>
        </div>
      </section>
      <Footer />
      <ScrollToTop showUnder={160}>
        <MdArrowUpward size={20} />
      </ScrollToTop>
    </div>
  );
}
